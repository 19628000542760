<script setup lang="ts">
import { useRouter } from 'vue-router';

const router = useRouter();
</script>
<template>
  <div class="sidebar">
    <a href="https://getkicks.io"><img class="logo" src="../assets/logo.png" /></a>
    <div class="center">
      <div :class="{ 'active': router.currentRoute.value.path == '/' }" @click="router.push({ name: 'Marketplace' })">
        <img src="../assets/store.png" />Marketplace
      </div>
      <div :class="{ 'active': router.currentRoute.value.path == '/my-nfts' }" @click="router.push({ name: 'MyNFTs' })">
        <img src="../assets/snaker.png" /> My NFTs
      </div>
      <a href="https://staking.getkicks.io" target="_blank">
        <img src="../assets/diamond.png" />Staking
      </a>
      <a href="https://sale.getkicks.io" target="_blank">
        <img src="../assets/buy.svg" />Minting
      </a>
    </div>

    <!-- <div class="sub-menu">
      <a href="https://staking.getkicks.io" target="_blank">Staking</a>
      <a href="https://sale.getkicks.io" target="_blank">Minting</a>
    </div> -->
    <a href="https://docs.getkicks.io/" target="_blank" class="whitepaper">Whitepaper</a>
    <div class="socials">
      <a href="https://t.me/GetKicks_official" target="_blank"><img src="../assets/telegram.png" /></a>
      <a href="https://www.instagram.com/getkicks_io" target="_blank"><img src="../assets/instagram.png" /></a>
      <a href="https://twitter.com/getkicks_io" target="_blank"><img style="margin-top: 3px;"
          src="../assets/twitter.png" /></a>
      <a href="https://discord.gg/get-kicks" target="_blank"><img style="margin-top: 3px;"
          src="../assets/discord.png" /></a>

    </div>
    <!-- <div class="float-right">
      <ConnectButton />
      <DisconnectButton />
    </div> -->
  </div>
</template>
<style scoped lang="scss">
.sidebar {
  z-index: 999;
  position: fixed;
  height: 95vh;
  top: 0px;
  left: 0px;
  width: 210px;
  background-color: #000;
  color: #f3f3f3;
  float: left;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
  border-radius: 14px;
  margin: 2.5vh 0 0 30px;

  .whitepaper {
    width: 148px;
    height: 40px;
    line-height: 40px;
    color: #fff;
    font-weight: bold;
    text-align: center;
    display: block;
    text-decoration: none;
    border: 2px solid #FFFFFF;
    border-radius: 50px;
    position: absolute;
    bottom: 100px;
    left: 31px;
    font-size: 16px;

    &:hover {
      background: #fff;
      color: #000;
    }
  }

  .float-right {
    margin-right: 50px;
  }

  .sub-menu {
    margin-top: 200px;
    margin-left: 40px;

    a {
      text-decoration: none;
      margin-bottom: 15px;
      display: block;
      font-weight: 400;
      font-size: 16px;
      color: #969696;
      text-align: left;
    }
  }

  .center {
    width: 100%;
    height: auto;
    float: left;
    margin-top: 0px;

    div,
    a {
      display: block;
      width: 170px;
      height: 40px;
      line-height: 40px;
      margin: 15px auto;

      border-radius: 27px;
      text-align: left;
      font-weight: bold;

      font-weight: 700;
      font-size: 14px;
      color: #969696;

      img {
        margin-bottom: -6px;
        margin-right: 10px;
        margin-left: 15px;
        filter: invert(70%) sepia(0%) saturate(2%) hue-rotate(278deg) brightness(85%) contrast(91%);
      }
    }

    .active {
      background: #F30318;
      color: #fff;

      img {
        filter: brightness(0) invert(1);

      }

    }

    &:hover {
      cursor: pointer;
    }
  }

  .socials {
    position: absolute;
    bottom: 40px;
    left: 30px;
    width: 160px;
    height: 25px;

    a {
      float: left;
      width: 30px;
      margin-right: 10px;

      img {
        width: 20px;
      }
    }
  }

  .margin-50 {
    margin: 50px;
  }

  div {
    height: 100%;
  }

  // img {
  //   height: 100%;
  // }
  .logo {
    width: 120px;
    margin: 20px auto;
  }
}
</style>