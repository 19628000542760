<script setup lang="ts">
import Card from "@/components/Card.vue";
import ListViewItem from "@/components/ListViewItem.vue";
import { ref, PropType } from "vue";
import { Listing } from "@/types";
const width = ref<number>(window.innerWidth);
const { listings, bth, listViewToggle } = defineProps({
  listings: {
    default: [],
    type: Array as PropType<Array<Listing>>,
  },
  bth: {
    default: 0,
    type: Number,
  },
  listViewToggle: {
    default: false,
    type: Boolean,
  },
  hasPrice: {
    default: true,
    type: Boolean,
  },
});
</script>
<template>
  <div>
    <div v-if="!listViewToggle && width > 1400" class="list">
      <ListViewItem v-for="listing in listings" :key="listing.listingId" :bth="bth" :listing="listing" />
    </div>
    <div v-else class="cards">
      <Card v-for="listing in listings" :key="listing.listingId" :item="listing" :bth="bth" :myNFT="false"
        :hasPrice="hasPrice" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.list {
  width: 100%;
  height: auto;
  float: left;
  margin-top: 30px;
}

.cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  column-gap: 20px;
}

@media (max-width: 2000px) {
  .cards {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media (max-width: 1600px) {
  .cards {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 1200px) {
  .cards {
    grid-template-columns: 1fr 1fr !important;
  }
}

@media (max-width: 1200px) {
  .cards {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 800px) {
  .cards {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 768px) {
  .cards {
    grid-template-columns: 1fr !important;
  }
}
</style>
