<template>
  <div class="container" @mouseover="hover = true" @mouseleave="hover = false">
    <router-link class="left" :to="'/listing/' + listing.listingId">
      <div class="circle" :class="getRarityClass(listing.rarity)">
        <img :src="getImage('nfts', listing.name)" />
      </div>
      <div class="vertical-line"></div>
      <div class="season">{{ listing.season }}</div>
      <div class="rarity">
        {{ listing.rarity }}
      </div>
      <div class="name">{{ listing.name }}</div>
    </router-link>
    <div class="right">
      <div class="info-segment">
        <span class="tag">Replicas</span>
        <div class="value-field">
          {{ listing.replicas }}
        </div>
      </div>
      <div class="info-segment">
        <span class="tag">Price</span>
        <div class="value-field">
          {{ listing.price }} BTH
          <!-- <span class="usdEstimate" v-if="lpAddress()">≈{{ Math.round(bth * listing.price) }} BUSD</span> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { getRarityClass, getImage } from "@/plugins";
import { ref } from "vue";
defineProps(["bth", "listing"]);

// const name = ref<string>("wally_bets");
// const rarity = ref<string>("Epic");
// const season = ref<string>("Season1");
// const replicas = ref<number>(10);
// const price = ref<number>(250);
// const isCharacter = ref<boolean>(false);
const hover = ref<boolean>(false);

</script>
<style scoped lang="scss">
.container {
  width: 100%;
  height: 150px;
  background: grey;
  margin-right: 20px;
  margin-bottom: 20px;
  background: linear-gradient(180deg, #2e2e3c 55.66%, #23232f 125.89%);
  box-shadow: 0px 4px 45px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  cursor: pointer;

  .left,
  .right {
    width: 50%;
    height: 150px;
    float: left;
    position: relative;
  }

  $size: 100px;

  .circle {
    background: red;
    height: $size;
    width: $size;
    border-radius: calc($size / 2);
    float: left;
    margin-top: 25px;
    margin-left: 40px;

    img {
      width: 100%;
      max-width: 100px;
      max-height: 120px;
      object-fit: contain;
      top: -10px;
      position: relative;
    }
  }

  .circle-fade {
    height: $size;
    width: $size;
    border-radius: calc($size / 2);
    margin: auto;
    position: absolute;
    top: 50px;

    background: linear-gradient(132.71deg, #f1cb69 -12.6%, #f17969 112.6%);
    opacity: 0.5;
    filter: blur(150px);
  }

  .legendary {
    background: linear-gradient(90deg,
        #fcb819 33.58%,
        #f99124 85.82%) !important;
    // background: #fcb819 !important;
  }

  .rare {
    background: linear-gradient(90deg,
        #960de7 33.58%,
        #7418f6 85.82%) !important;
  }

  .common {
    background: linear-gradient(90deg,
        #00d1e6 33.58%,
        #00dacb 85.82%) !important;
  }

  .super_rare {
    background: linear-gradient(90deg,
        #e15288 33.58%,
        #d941a9 85.82%) !important;
  }

  .epic {
    background: linear-gradient(90deg,
        #f3793d 33.58%,
        #e4597b 85.82%) !important;
  }

  .uncommon {
    background: linear-gradient(90deg,
        #00e4aa 33.58%,
        #00e999 85.82%) !important;
  }

  .info-segment {
    width: 110px;
    height: 85px;
    float: left;
    text-align: center;
    margin-left: 30px;
    margin-top: 30px;

    .tag {
      width: 100%;
      color: #717185;
      margin-top: 15px;
      display: block;
      font-size: 13px;
    }

    .value-field {
      width: 60px;
      height: auto;
      font-size: 14px;
      color: var(--white);
      padding: 8px 10px;
      background: linear-gradient(89.97deg, #2e2e3c -87.81%, #23232f 85.79%);
      border-radius: 5px;
      margin: 5px auto;
    }
  }

  .name {
    /* Parapgraph/Caps/Extra Bold */
    width: 100%;
    height: auto;
    margin-top: 65px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 152.4%;
    /* or 24px */

    text-align: center;
    letter-spacing: 0.035em;
    text-transform: uppercase;

    /* White */

    color: var(--white);
  }

  .season {
    width: 80px;
    height: 30px;
    left: 15px;
    top: 15px;
    text-align: center;
    line-height: 30px;
    color: var(--white);
    font-weight: 600;
    float: right;
    margin-top: 60px;
    margin-right: 30px;

    background: linear-gradient(109.12deg, #fd2929 -14.05%, #fe66c1 134.06%);
    border-radius: 5px;
  }

  .rarity {
    width: 90px;
    height: 30px;
    color: #717185;
    margin-top: 50px;
    float: right;
    margin-right: 30px;

    .rarity-holder {
      margin: 10px auto !important;
    }
  }

  .vertical-line {
    height: 120px;
    width: 1px;
    margin-top: 15px;
    float: right;
    background: linear-gradient(271.06deg, #2f2f42 -318.02%, #717185 936.92%);
  }

  .title {
    font-size: 24px;
  }

  button {
    color: white;
    background: green;
    border: 1px solid grey;
    border-radius: 3px;
    padding: 5px 10px;
  }

  .usdEstimate {
    width: 100%;
    float: left;
    display: block;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 152.4%;
    /* identical to box height, or 20px */

    text-align: right;
    letter-spacing: 0.035em;

    /* Tint */

    color: #9393a4;
  }
}
</style>
