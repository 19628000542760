<template>
  <div class="filters-rel">
    <div class="filter-button" v-if="width < 768 && router.currentRoute.value.path != '/my-nfts'"
      @click="showMobileMenu = !showMobileMenu"><img src="../assets/filter.png" />Filters</div>
    <div class="filters" v-if="width > 768 || (width < 768 && showMobileMenu)">
      <h2 v-if="width < 768"> Filters </h2>
      <div class=" scroll">
        <img v-if="width <= 768" class="cross" @click="showMobileMenu = !showMobileMenu"
          src="@/assets/cross-black.svg" />
        <div class="row row1 flex" v-if="marketplaceActive">
          <div class="select-wrapper" v-for="name of dropdownFilters" :key="name">
            <select v-model="filters[name].value" :class="name">
              <option selected disabled value="">{{ filters[name].key }}</option>
              <option v-for="option in filters[name].options" :key="name + option" :value="option">
                {{ mapOption(option) }}
              </option>
            </select>
            <img style="position: absolute; right: 25px; top: 16px;" src="../assets/down_arrow.png" />
          </div>
        </div>
        <div class="row row2" :class="{ topmargin: !marketplaceActive }">
          <div class="box" v-if="router.currentRoute.value.path != '/my-nfts'" @click="resetFilters">Reset Filters <img
              src="../assets/cross.png" /></div>

          <div class="results" v-if="marketplaceActive">
            {{ listingCount }} Results
          </div>
          <div class="results" v-else>{{ myNFTs.length }} NFTs</div>
          <select style="float: right;" key="forSale" v-model="filters['forSale'].value"
            v-if="!marketplaceActive && router.currentRoute.value.path != '/my-nfts'">
            <option v-for="option in filters['forSale'].options" :key="'forSale' + option" :value="option">
              {{ option }}
            </option>
          </select>
          <!-- <div class="toggle-view" v-if="marketplaceActive" @click="toggleListView">
          <input type="checkbox" name="filter5" id="filter5" v-model="listViewToggle" style="display: none" />
          <img src="@/assets/marketplace/lists.svg" v-if="listViewToggle" class="view-toggle" />
          <img src="@/assets/marketplace/cards.svg" v-if="!listViewToggle" class="view-toggle" />
        </div> -->
        </div>
        <div class="save" v-if="width < 768" @click="showMobileMenu = !showMobileMenu">
          Save
        </div>
      </div>
    </div>
    <div v-else>
      <div class="mobile-filters">
        <div class="filter-button" @click="showMobileMenu = !showMobileMenu">
          Filter
        </div>
      </div>
    </div>
  </div>

</template>
<script setup lang="ts">
import {
  filtersToRequestBody,
} from "@/plugins";
import { useStore } from "@/store";
import { storeToRefs } from "pinia";
import { onBeforeMount, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();

const emit = defineEmits(["update:filters", "update:listView"]);
const store = useStore();
const { myNFTs } = storeToRefs(store);

const initialFilterState = () => ({
  category: {
    value: "",
    isWhere: true,
  },
  pageSize: {
    value: 12,
  },
  rarity: {
    value: "",
    key: "Rarity",
    options: ["Brick", "B-Grade", "OG", "Heat", "Grail"],
    isAttribute: true,
  },
  drop: {
    value: "",
    key: "Drop",
    options: ["Genesis"],
    isAttribute: false,
  },
  status: {
    value: "Added",
    key: "Status",
    isWhere: true,
    options: ["Added", "Executed", "Cancelled"],
  },
  orderBy: {
    value: "",
    key: "Sort by",
    options: ["timeAdded", "price"],
  },
  forSale: {
    value: "All",
    key: "All",
    options: ["All", "For Sale", "Not For Sale"],
  },
});

const { listingCount, marketplaceActive } = defineProps({
  listingCount: {
    type: Number,
    required: true,
    default: 0,
  },
  marketplaceActive: {
    type: Boolean,
    required: true,
    default: true,
  },
});
const width = ref<number>(window.innerWidth);
const showMobileMenu = ref<boolean>(false);
const listViewToggle = ref<boolean>(true);
const dropdownFilters = ref<Array<string>>([
  "rarity",
  "drop",
  "status",
  "orderBy",
]);
defineExpose({
  showMobileMenu,
})
const filters = reactive({} as any);
const labels = reactive({
  timeAdded: "Recently listed",
  price: "Price",
});
onBeforeMount(() => {
  Object.assign(filters, initialFilterState());
  watch(
    filters,
    () => {
      emit("update:filters", filtersToRequestBody(filters));
    },
    { deep: true, immediate: true }
  );
});

function resetFilters(): void {
  Object.assign(filters, initialFilterState());
}
function mapOption(string: string): string {
  if (filters.orderBy.options.includes(string)) {
    return labels[string];
  }
  return string;
}
</script>

<style scoped lang="scss">
// .filters-rel {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100vw;
//   height: 100vh;
// }

.flex {
  display: flex;

  select {
    flex-grow: 1;

    &:not(:last-child) {
      margin-right: 16px;
    }

    appearance: none;
  }
}

.relative-filter {
  width: 100%;
  height: 100%;
  float: left;
  position: relative;
}

.select-wrapper {
  width: fit-content;
  height: auto;
  float: left;
  position: relative;

  select {
    display: flex;
    float: left;
  }
}

.save {
  width: 94%;
  height: 50px;
  line-height: 50px;
  color: #fff;
  text-align: center;
  background: linear-gradient(96.12deg, #FF0017 -10.57%, #CB0315 77.99%);
  border-radius: 50px;
  width: 93%;
  margin-left: 3%;
  position: absolute;
  bottom: 95px;
  font-weight: bold;
  cursor: pointer;
}

.replicas {
  width: 120px;
}

.status {
  width: 100px;
}

.filters,
.mobile-filters {
  width: 600px;
  float: right;

  .cross {
    width: 20px;
    cursor: pointer;
    position: absolute;
    left: 5px;
    top: 0px;
  }

  input {
    cursor: pointer;
  }

  select {
    cursor: pointer;
    background: transparent;
  }

  .row {
    width: 90%;
    // display: flex;
    flex-direction: row;
  }

  $topmargin: 30px;

  .row1 {
    margin-top: $topmargin;
    width: fit-content;
    height: 50px;
    float: right;
  }

  .line {
    height: 1px;
    background: #454558;
    width: 100%;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    float: left;
  }

  .row2 {
    margin-bottom: 40px;
    float: left;
    margin-right: 15px !important;
    float: right;
    margin-top: 10px;
    position: relative;
    z-index: 55;
  }

  .topmargin {
    margin-top: $topmargin;
  }

  #filter2 {
    background: green;
    border-radius: 50%;
  }

  .autocomplete {
    position: relative;
    width: 40%;

    #filter1 {
      height: 50px;
      width: 100%;
      background: #23232f;
      float: right;
      /* Blue */

      border: 1px solid #454558;
      box-sizing: border-box;
      border-radius: 8px;
      color: var(--white);
      padding-left: 20px;

      &:hover {
        color: var(--white);
      }
    }

    #filter1::placeholder {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 152.4%;
      letter-spacing: 0.035em;
      color: #9393a4;

      &:hover {
        color: var(--white);
      }
    }

    .autocomplete-items {
      position: absolute;
      border: 1px solid #454558;
      background: linear-gradient(180deg, #2e2e3c 55.66%, #23232f 125.89%);
      box-shadow: 0px 4px 45px rgba(0, 0, 0, 0.15);
      border-bottom: none;
      border-top: none;
      z-index: 99;
      /*position the autocomplete items to be the same width as the container:*/
      top: 100%;
      left: 0;
      right: 0;
      border-radius: 7px;
      overflow-y: scroll;
      max-height: 500px;

      div {
        border-radius: 7px;

        padding: 10px;
        cursor: pointer;
        background-color: linear-gradient(180deg,
            #2e2e3c 55.66%,
            #23232f 125.89%);
        border-bottom: 1px solid #454558;
        color: var(--white);

        &:hover {
          background-color: #454558;
        }
      }

      .autocomplete-active {
        /*when navigating through the items using the arrow keys:*/
        // background-color: !important;
        background: linear-gradient(91.98deg,
            #3cc4d7 -30.49%,
            #6df3d3 191.13%,
            #61fea0 375.91%);
        color: var(--white);
      }
    }
  }

  .toggle-view {
    min-width: 50px;
    width: 50px;
    height: 50px;
    float: left;
    border: 1px solid #454558;
    box-sizing: border-box;
    border-radius: 8px;
    margin-right: 2%;
    cursor: pointer;

    .view-toggle {
      width: 60%;
      margin: auto;
      margin-top: 8px;
    }
  }

  .button {
    // display: flex;
    display: none;
    width: calc(100% - 50px);
    align-items: center;
    padding: 15px 25px;
    margin-top: -30px;
    background: rgb(35, 35, 47);
    border: 1px solid rgb(69, 69, 88);
    box-sizing: border-box;
    border-radius: 10px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 152.4%;
    letter-spacing: 0.035em;
    text-transform: uppercase;
    color: rgb(255, 255, 255);
    background: linear-gradient(87.46deg,
        rgb(60, 196, 215) -3.04%,
        rgb(109, 243, 211) 92.88%,
        rgb(97, 254, 160) 172.86%);
    border: none;
    float: left;
  }

  .wrapper {
    border-radius: 8px;

    .box {
      width: calc(100% - 1px);
      height: 30px;
      line-height: 30px;
      float: left;
      margin-right: 2%;
      box-sizing: border-box;
      border-radius: 8px;
      cursor: pointer;
      margin-right: 2%;
      background: #23232f;
      min-width: 80px;
      background: #EBEBEB;
      border: 1px solid #EBEBEB;
      border-radius: 49px;

      img {
        margin-left: 8px;
        margin-right: 8px;
      }

      .box-label {
        display: inline;
      }

      &:hover {
        font-weight: 600;
      }
    }
  }

  .box {
    width: auto;
    height: 30px;
    line-height: 30px;
    float: left;
    background: #EBEBEB;
    border: 1px solid #EBEBEB;
    border-radius: 49px;
    box-sizing: border-box;
    color: #4A4A4A;
    cursor: pointer;
    font-size: 12px;
    padding-left: 15px;
    padding-right: 15px;
    float: right;

    img {
      margin-left: 8px;
      margin-right: 8px;
      margin-bottom: -2px;
    }

    // &:hover {
    //   font-weight: 600;
    // }
  }

  .results {
    width: 20%;
    height: 50px;
    float: left;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 50px;
    float: right;
    color: #000000;
    margin-top: -10px;
  }

  .wrapper {
    width: 18%;
    height: 51px;
    float: left;
    margin-right: 2%;
    padding-top: 1px;
    padding-left: 1px;
  }

  .activeType {
    background: linear-gradient(180deg,
        #3cc4d7 0%,
        #6df3d3 54.53%,
        #61fea0 100%);
  }

  select {
    width: 100px;
    height: 40px;
    border: 1px solid #454558;
    box-sizing: border-box;
    border-radius: 8px;
    background: transparent;
    border: 1px solid #EBEBEB;
    color: var(--black);
    padding-left: 10px;
    font-weight: bold;
    font-size: 14px;
    border-radius: 20px;
    // padding-left: 20px;
    // padding-right: 20px;
  }

  .filter-popular {
    width: 15%;
    float: right;

    select {
      width: 100%;
    }
  }
}

.mobile-filters {
  display: none;

  .filter-button {
    width: 150px;
    height: 50px;
    line-height: 50px;
    margin: 0 auto;
    background: #23232f;
    border: 1px solid #454558;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 0;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    letter-spacing: 0.035em;
    text-transform: uppercase;
    cursor: pointer;
    color: var(--white);
    float: left;
    margin-right: 20px;
    display: none;
  }
}

@media only screen and (max-width: 1400px) {
  .marketplace .filters .wrapper {
    width: 31.84%;
    margin-bottom: 20px;
  }

  .last-wrapper {
    margin-right: 0 !important;
  }

  .marketplace .filters .row2 {
    display: block;
  }

  .marketplace .filters .filter-popular {
    width: 31.84%;
  }
}

@media only screen and (max-width: 1024px) {
  .filters {
    margin-top: 20px;
  }

  .nft-toggle {
    margin-top: 100px;
  }

  .marketplace {
    width: calc(100% - 50px);
    margin-left: 0;
    left: 0;
    margin-top: 220px;
    padding-left: 20px;
    padding-right: 20px;

    .wrapper {
      .box {
        .box-label {
          display: none;
        }
      }
    }
  }

  .filters-rel {
    margin-top: 80px;
  }
}

@media only screen and (max-width: 800px) {
  .filters {
    h2 {
      font-size: 24px;
      float: left;
      margin-left: 20px;
      margin-top: 40px;
    }

    .cross {
      right: 40px;
      left: inherit;
      top: 38px;
      width: 18px;
    }
  }


  .marketplace .filters {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    height: auto;
    width: 100vw;
    background: rgb(35, 35, 47);
    z-index: 555;
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
    // overflow-y: auto;
    // overflow-x: hidden;



    .scroll {
      height: calc(100vh - 100px);
      width: 100%;
      float: left;
      position: relative;
      overflow-y: scroll;
      overflow-x: hidden;
    }

    #floorTiles {
      margin-top: -20px;
      margin-bottom: 50px;
    }

    .button {
      display: block;
    }

    select {
      width: 100%;
      margin-bottom: 15px;
    }

    .autocomplete {
      display: none;
    }

    .line {
      width: calc(100% - 40px);
    }

    .wrapper {
      width: 100%;
    }

    .box {
      width: 100%;
      margin-bottom: 10px;
    }

    .toggle-view {
      display: none;
    }

    .results {
      width: auto;
    }
  }

  .marketplace .filters .wrapper {
    width: 100%;
  }

  .marketplace .filters .row {
    display: block;
    flex-direction: inherit;
    height: auto;
    width: calc(100% - 50px);
    position: relative;
  }


  // .filters {
  //   display: none;
  // }
  .marketplace .mobile-filters {
    margin-bottom: 30px;
    margin-top: 20px;
    display: block;
    float: left;

    .autocomplete {
      float: left;
      width: calc(100% - 170px);

      #filter1::placeholder {
        font-size: 10px;
      }
    }
  }
}

@media (max-width: 780px) {
  .filters .row2 {
    position: absolute;
    top: inherit;
    bottom: 90px;
    width: 100%;
  }

  .filters {
    position: fixed;
    background: #fff;
    width: 100%;
    height: calc(100vh - 75px);
    z-index: 555;
    left: 0;
    top: 75px;
  }



  .select-wrapper {
    width: 100% !important;
    margin: 10px auto;
  }

  select {
    width: 100% !important;
  }

  .flex {
    display: block;
  }

  .row1 {
    width: 94% !important;
    margin-left: 2.5%;
    margin-top: 80px auto !important;
    float: none !important;
  }

  .results {
    display: none;
  }

  .filters .box {
    position: absolute;
    bottom: 30px;
    left: 3%;
    margin-left: 0 !important;
    z-index: 555;
    width: 93%;
    margin-left: 2.5%;
    border: 2px solid #0D0D0D;
    border-radius: 50px;
    height: 50px;
    line-height: 50px;
    background: transparent;
    font-size: 16px;
    color: #0D0D0D;
    font-weight: bold;

    img {
      display: none;
    }
  }

  .filter-button {
    width: 80%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: #0D0D0D;
    background: #FFFFFF;
    border: 2px solid #0D0D0D;
    box-shadow: 0px 5px 35px -10px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    position: fixed;
    bottom: 30px;
    margin-left: 4%;
    z-index: 55;
    cursor: pointer;
    font-weight: bold;
    margin-left: 0;

    img {
      margin-bottom: -3px;
      margin-right: 7px;
    }
  }
}
</style>
