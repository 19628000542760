import { Listing, Nft } from "@/types";
import { unref } from "@vue/reactivity";

export const splitCamelCase = (string) => {
  return string.replace(/([a-z])([A-Z])/g, "$1 $2");
};

export const getRarityClass = (rarity) => {
  return rarity.replaceAll(" ", "_").toLowerCase();
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const filtersToRequestBody = (filters) => {
  // if (!filters) {
  //   filters = this.filters;
  // }
  const _filters = {
    attributes: [],
    where: {},
  };
  Object.keys(filters).forEach((key) => {
    if (filters[key].isAttribute && filters[key].value) {
      _filters.attributes.push({
        trait_type: filters[key].key,
        value: filters[key].value,
      });
    } else if (filters[key].isWhere && filters[key].value) {
      if (key == "category") {
        _filters.where["nft"] = {
          collection: {
            category: {
              name: filters[key].value,
            },
          },
        };
      } else {
        _filters.where[key] = filters[key].value;
      }
    } else if (
      !filters[key].isWhere &&
      !filters[key].isAttribute &&
      filters[key].value
    ) {
      _filters[key] = filters[key].value;
    }
  });
  if (filters.orderBy.value != "") {
    const order = {};
    order[filters.orderBy.value] = "asc";
    Object.assign(_filters, {
      orderBy: [order],
    });
  }
  if (Object.keys(_filters.where).length == 0) {
    delete _filters.where;
  }
  return _filters;
};

/**
 * Formats
 * Ms. Kitty -> ms_kitty
 */
export const formatName = (str) => {
  return str.replaceAll(" ", "_").A(".", "");
};

/**
 *
 * @param {karakters2 | rooms | nfts} folder
 * @param {nft name} name
 * @returns
 */
export const getImage = (folder, name) => {
  if (!name || !folder) {
    console.log({ name, folder });
    throw new Error("wrong parameters");
  }
  name = name.replaceAll(".", "");
  // return `@/assets/${folder}/${name}.png`;
  return new URL(`../assets/${folder}/${name}.png`, import.meta.url).href;
};

/**
 *
 * @param {karakter-gif | background} folder
 * @param {name | rarity} name
 * @returns required object
 */
export const getGif = (folder, name) => {
  name = name.replaceAll(" ", "_").replaceAll(".", "");
  // return `@/assets/${folder}/${name}.gif`;
  return new URL(`../assets/${folder}/${name}.gif`, import.meta.url).href;
};

export const CopyToClipboard = function (text) {
  const textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand("copy");
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
  }

  document.body.removeChild(textArea);
};

export const Email = {
  send: function (a) {
    return new Promise(function (n) {
      (a.nocache = Math.floor(1e6 * Math.random() + 1)), (a.Action = "Send");
      const t = JSON.stringify(a);
      Email.ajaxPost("https://smtpjs.com/v3/smtpjs.aspx?", t, function (e) {
        n(e);
      });
    });
  },
  ajaxPost: function (e, n, t) {
    const a = Email.createCORSRequest("POST", e);
    a.setRequestHeader("Content-type", "application/x-www-form-urlencoded"),
      (a.onload = function () {
        const e = a.responseText;
        null != t && t(e);
      }),
      a.send(n);
  },
  ajax: function (e, n) {
    const t = Email.createCORSRequest("GET", e);
    (t.onload = function () {
      const e = t.responseText;
      null != n && n(e);
    }),
      t.send();
  },
  /* eslint-disable */
  /* XDomainRequest */
  createCORSRequest: function (e, n) {
    var t = new XMLHttpRequest();
    return (
      "withCredentials" in t
        ? t.open(e, n, !0)
        : // @ts-ignore
        "undefined" != typeof XDomainRequest
        ? // @ts-ignore
          (t = new XDomainRequest()).open(e, n)
        : (t = null),
      t
    );
  },
};

export function restructureNftData(nfts: Array<any>): Array<Nft> {
  if (!nfts || !nfts.length) return [];
  let temp = nfts;
  temp = temp.map((nft) => {
    let metadata = nft.metadata.data;
    return {
      ...nft,
      address: nft.address,
      tokenId: nft.tokenId,
      name: metadata.name,
      description: metadata.description,
      image: metadata.image,
      external_link: metadata.external_link,
      rarity: getTraitValue(metadata.attributes, "Rarity"),
      replicas: getTraitValue(metadata.attributes, "Replicas"),
      season: getTraitValue(metadata.attributes, "Drop"),
      floorTiles: getTraitValue(metadata.attributes, "Floor Tiles"),
    };
  });
  return temp;
}

export function restructureRoomData(room): Nft {
  if (!room) return null;
  let metadata = room.roomCollection.ipfs;
  delete metadata.id;
  return {
    ...room,
    ...metadata,
    image: room?.image,
    address: room.roomCollection.address,
    tokenId: room.tokenId,
    season: metadata.drop,
    price: room.roomCollection.price,
  };
}

export function restructureListingData(listing): Listing {
  if (!listing) return null;
  const nft = listing.nft;
  const result = {
    ...listing,
    nft: restructureNftData([nft])[0],
  };
  return result;
}

export function getTraitValue(attributes, trait_type) {
  let temp = attributes.find((item) => item.trait_type == trait_type);
  if (temp != undefined) {
    return temp.value;
  }
  return null;
}

export function restructureAPIData(listings) {
  let _listings = unref(listings);
  if (!_listings || !_listings.length) return [];
  _listings = _listings.map((listing) => {
    let metadata: any = {};
    try {
      metadata = listing.nft.metadata.data;
    } catch (e) {
      return null;
    }
    if (!metadata.attributes) {
      return null;
    }
    return {
      ...listing,
      type: listing.category || listing.type,
      name: metadata.name,
      description: metadata.description,
      image: metadata.image,
      external_link: metadata.external_link,
      rarity: getTraitValue(metadata.attributes, "Rarity"),
      replicas: getTraitValue(metadata.attributes, "Replicas"),
      season: getTraitValue(metadata.attributes, "Drop"),
      floorTiles: getTraitValue(metadata.attributes, "Floor Tiles"),
      address: listing.nft.address,
      tokenId: listing.nft.tokenId,
    };
  });
  return _listings.filter((listing) => listing != null);
}
