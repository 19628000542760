<script setup lang="ts">
import { getListings, getListingPageCount, getCollectionStats } from "@/api";
// Components
import Listings from "@/components/Listings.vue";
import ListingFilters from "@/components/ListingFilters.vue";
import Loading from "@/components/Loading.vue";
import { ref, onMounted, reactive } from "vue";
import { computed, unref } from "@vue/reactivity";
import ConnectButton from '@/wallet/components/ConnectButton.vue';
import DisconnectButton from '@/wallet/components/DisconnectButton.vue';
import WalletBalance from "@/wallet/components/WalletBalance.vue";
import WalletAddress from "@/wallet/components/WalletAddress.vue";
import { useRouter } from 'vue-router';

const router = useRouter();

const bthPrice = ref<number>(0);
const pageRangeA = ref<number>(1);
const pageRangeB = ref<number>(3);
const listings = ref<Array<any>>([]);
const listingFiltersRef = ref(null);
const listViewToggle = ref<boolean>(true); // listview
const page = reactive<{ current: number, total: number, array: Array<any> }>({
  current: 0,
  total: 0,
  array: [],
});
const filters = ref<object>({});
const loading = ref<boolean>(true);
const count = ref<number>(0);
const hasPrice = ref<boolean>(true);
const floorPrice = ref<number>("..." as any as number);
const volume = ref<number>("..." as any as number);

onMounted(async () => {
  const stats = await getCollectionStats(import.meta.env.VITE_COLLECTION_ADDRESS);
  floorPrice.value = stats.floor_price;
  volume.value = stats.total_volume;
});

async function updatePages() {
  const result = await getListings(filters.value, page.current);
  let _listings = result.listings;
  count.value = result.count;
  listings.value = restructureAPIData(_listings);
}

async function updateFilters(_filters) {
  filters.value = _filters;
  loading.value = true;
  page.current = 0;
  if (Object.keys(_filters).length == 0) {
    return;
  }
  const result = await getListings(_filters, page.current);
  let _listings = result.listings;
  count.value = result.count;
  listings.value = restructureAPIData(_listings);
  let pageCount = await getListingPageCount(_filters);
  page.total = Math.ceil(pageCount);
  // Creates array 0 to n (page.total)
  page.array = Array.from({ length: page.total }, (_, i) => i);
  loading.value = false;
}
// TODO: move this to helpers
function restructureAPIData(listings) {
  let _listings = unref(listings);
  if (!_listings || !_listings.length) return [];
  _listings = _listings.map((listing) => {
    let metadata: any = {};
    try {
      metadata = listing.nft.metadata.data;
    } catch (e) {
      return null;
    }
    if (!metadata.attributes) {
      return null;
    }
    return {
      ...listing,
      type: listing.nft.collection.category.name || listing.type,
      name: metadata.name,
      description: metadata.description,
      image: metadata.image,
      external_link: metadata.external_link,
      rarity: getTraitValue(metadata.attributes, "Rarity"),
      replicas: getTraitValue(metadata.attributes, "Replicas"),
      season: getTraitValue(metadata.attributes, "Drop"),
      floorTiles: getTraitValue(metadata.attributes, "Floor Tiles"),
      address: listing.nft.address,
      tokenId: listing.nft.tokenId,
    };
  });
  return _listings.filter((listing) => listing != null);
}
function getTraitValue(attributes, trait_type) {
  let temp = attributes.find((item) => item.trait_type == trait_type);
  if (temp != undefined) {
    return temp.value;
  }
  return null;
}
function switchPage(_page) {
  if (_page < 0 || _page > _page.total - 1) return;
  page.current = _page;
  if (_page == 1) {
    setRange(1, 3);
    return;
  }
  if (_page == page.total) {
    setRange(page.total - 2, page.total);
    return;
  }
  setRange(_page, _page + 2);
}
function setRange(a, b) {
  updatePages();
  if (a < 1 || b > page.total) return;
  pageRangeA.value = a;
  pageRangeB.value = b;
}
function shiftRange(leftRight) {
  // this.page.current += leftRight;
  if (leftRight == -1 && this.pageRangeA > 1) {
    this.pageRangeA -= 1;
    this.pageRangeB -= 1;
  } else if (leftRight == 1 && this.pageRangeB < this.page.total) {
    this.pageRangeA += 1;
    this.pageRangeB += 1;
  } else {
    //  == 0
  }
}
</script>
<template>
  <div>
    <!-- <div class="logo"><img src="../assets/logo.png" /></div> -->
    <div class="connect-button">
      <ConnectButton class="connect-wallet" />
      <DisconnectButton class="disconnect-wallet" />
    </div>
    <div class="info-holder">
      <!-- <WalletAddress class="address" /> -->
      <WalletBalance class="balance" />
      <img class="busd" src="../assets/smartchain_logo.png" />
      <span class="bsc">Binance Smart Chain</span>
    </div>

    <div class="buy-kicks-desktop">
      <a href="https://www.huobi.com/en-us/exchange/kicks_usdt" target="_blank"><img src="../assets/huobi.png" /></a>
      <a href="https://www.gate.io/nl/trade/KICKS_USDT" target="_blank"><img src="../assets/gate.png" /></a>
      <a href="https://www.kucoin.com/nl/trade/KICKS-USDT" target="_blank"><img style="width:20px; margin-top: 3px;"
          src="../assets/kucoin.png" /></a>
      <a href="https://www.mexc.com/exchange/KICKS_USDT" target="_blank"><img src="../assets/mexc.png" /></a>
      <span>Buy KICKS</span>
    </div>

    <div class="mobile-menu">
      <a href="/" :class="{ 'active': router.currentRoute.value.path != '/my-nfts' }">Marketplace</a>
      <a href="/my-nfts" :class="{ 'active': router.currentRoute.value.path == '/my-nfts' }">My NFTs</a>
    </div>
    <div class="top-bar">
      <div class="stats">
        <div class="buy-kicks">
          <a href="https://www.huobi.com/en-us/exchange/kicks_usdt" target="_blank"><img
              src="../assets/huobi.png" /></a>
          <a href="https://www.gate.io/nl/trade/KICKS_USDT" target="_blank"><img src="../assets/gate.png" /></a>
          <a href="https://www.kucoin.com/nl/trade/KICKS-USDT" target="_blank"><img src="../assets/kucoin.png" /></a>
          <a href="https://www.mexc.com/exchange/KICKS_USDT" target="_blank"><img src="../assets/mexc.png" /></a>
          <span>Buy KICKS</span>
        </div>
        <div class="stat-box">
          <span class="title">Floor price</span>
          <span class="value">{{ Math.round(floorPrice) }} KICKS</span>
        </div>
        <div class="stat-box">
          <span class="title">Volume</span>
          <span class="value">{{ Math.round(volume) }} KICKS</span>
        </div>
      </div>
      <listing-filters @update:filters="updateFilters" @update:listView="listViewToggle = $event" :listingCount="count"
        :marketplaceActive="true" />
    </div>
    <div class="marketplace">
      <Loading class="loading" v-if="loading" :width="50" :height="50" />
      <div class="listings" v-else-if="listings.length > 0">
        <Listings :listings="listings" :bth="bthPrice" :hasPrice="hasPrice" :listViewToggle="listViewToggle" />
      </div>
      <p class="text" v-else>No listings found</p>

      <!-- Pagination -->
      <div class="pagination" v-if="listings.length > 0 && !loading">
        <div v-if="page.total > 5" class="pagenumber navigate left" @click="switchPage(page.current - 1)">
          <img class="arrow" src="@/assets/left.svg" />
        </div>
        <div v-if="pageRangeA > 1 && page.total > 5" class="pagenumber" @click="switchPage(1)"
          :class="{ active: page.current == 1 }">
          1
        </div>
        <div v-if="pageRangeA > 2 && page.total > 5" class="pagenumber">...</div>
        <div v-if="page.total > 5">
          <div class="pagenumber" v-for="_page in page.array.slice(pageRangeA - 1, pageRangeB)" :key="_page"
            @click="switchPage(_page)" :class="{ active: page.current == _page }">
            {{ _page + 1 }}
          </div>
        </div>
        <div v-else>
          <div class="pagenumber" v-for="_page in page.array" :key="_page" @click="switchPage(_page)" :class="{
            active: page.current == _page
          }">
            {{ _page + 1 }}
          </div>
        </div>
        <div v-if="page.total > 5 && pageRangeB < page.total - 1" class="pagenumber">
          ...
        </div>
        <div v-if="page.total > 5 && pageRangeB != page.total" class="pagenumber" @click="switchPage(page.total - 1)"
          :class="{ active: page.current == page.total - 1 }">
          {{ page.total }}
        </div>
        <div v-if="page.total > 5" class="pagenumber navigate right" @click="switchPage(page.current + 1)">
          <img class="arrow" src="@/assets/right.svg" />
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.logo {
  position: absolute;
  left: 20px;
  top: 20px;
  width: 100px;
}

.connect-button {
  position: absolute;
  right: 30px;
  top: 30px;

  .disconnect-wallet,
  .connect-wallet {
    background: linear-gradient(96.12deg, #FF0017 -10.57%, #CB0315 77.99%);
    border-radius: 50px;
    color: #fff;
  }

}

.buy-kicks-desktop {
  width: 230px;
  position: absolute;
  top: 45px;
  left: 283px;

  img {
    float: left;
    width: 25px;
    margin-right: 8px;
  }

  span {
    margin-top: 2px;
    display: block;
    float: left;
    font-size: 14px;
    font-weight: 600;
  }
}

.top-bar {
  width: calc(100% - 250px);
  margin-left: 250px;
  height: auto;
  float: left;
  margin-bottom: 40px;
  margin-top: 60px;
  position: relative;

  .buy-kicks {
    width: 190px;
    position: absolute;
    top: 0;
    margin: auto;
    left: 0;
    right: 0;
    display: none;

    img {
      float: left;
      width: 20px;
      margin-right: 8px;
    }

    span {
      margin-top: 2px;
      display: block;
      float: left;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.stats {
  width: auto;
  height: 80px;
  float: left;
  position: relative;

  .stat-box {
    height: auto;
    padding: 20px 20px;
    background: #F5F5F5;
    border-radius: 13px;
    margin-right: 15px;
    float: left;
    text-align: left;
    margin-top: 30px;

    .title {
      font-size: 13px;
      color: #969696;
      font-weight: 400;
      display: block;
    }

    .value {
      font-weight: bold;
      font-size: 16px;
      line-height: 25px;
      color: #000000;
      margin-top: 0px;
      display: block;
    }
  }
}

.flex {
  display: flex;

  select {
    flex-grow: 1;

    &:not(:last-child) {
      margin-right: 16px;
    }
  }
}

.arrow {
  margin-top: 6px !important;
  filter: brightness(0);

  &:hover {
    filter: brightness(0) invert(1);
  }
}

.loading {
  position: relative;
  margin-top: 50px;
  margin-bottom: 10px;
}

.text {
  margin: auto;
  margin-top: 16px;
  font-size: 20px;
  color: #000;
  padding: 6px;
  font-size: 16px;
}

.pre-order {
  overflow-x: hidden !important;
  overflow-y: hidden;
  height: auto;
  width: 100vw;
  position: relative;
}

.marketplace {
  width: calc(100% - 250px);
  height: auto;
  float: left;
  display: block;
  margin-left: 250px;

  .listings {
    width: 100%;
    background: #fff;
  }

  .cards {
    width: 100%;
    float: left;
    display: flex;
    flex-wrap: wrap;
  }

  .container:nth-child(4n + 0) {
    margin-right: 0;
  }

  .list {
    width: 100%;
    height: auto;
    float: left;
    margin-top: 30px;
  }

  .pagination {
    color: var(--white);
    display: block;
    margin: auto;
    width: 100%;
    margin: 35px auto;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    .navigate {
      width: 40px;
      height: 40px;
      border: 1px solid #454558;
      box-sizing: border-box;
      border-radius: 5px;
      float: left;
      margin-top: -5px;

      img {
        margin-top: 8px;
      }
    }

    .left {
      margin-right: 10px !important;
    }

    .right {
      margin-left: 10px !important;
    }

    .pagenumber {
      float: left;
      padding: 7px 7px 5px 7px;
      cursor: pointer;
      border-radius: 5px;
      font-weight: 500;
      float: left;
      // margin: 0 10px -10px 2px;
      margin-left: 5px;
      margin-right: 5px;
      font-weight: 600;
      color: #000;

      &:hover {
        background: #000;
        color: #fff;
      }
    }

    .active {
      background: #000 !important;
      color: #fff;
    }
  }
}

.mobile-menu {
  margin-top: 70px !important;
  width: 220px;
  margin: 0 auto;
  height: 30px;
  display: none;

  a {
    padding: 0 10px;
    height: 40px;
    line-height: 40px;
    margin: 15px auto;
    border-radius: 27px;
    text-align: left;
    font-weight: bold;
    font-weight: 700;
    font-size: 14px;
    display: block;
    color: #1e1c1a;
    float: left;
    margin-right: 20px;
  }

  .active {
    background: linear-gradient(96.12deg, #FF0017 -10.57%, #CB0315 77.99%);
    color: #fff;
  }
}

@media only screen and (max-width: 1024px) {
  .buy-kicks-desktop {
    display: none;
  }
  .buy-kicks {
    display: block !important;
  }
  .mobile-menu {
    display: block;
    width: 230px;
  }

  .nft-toggle {
    top: 100px;
  }

  .marketplace {
    width: calc(100% - 40px);
    margin-left: 0;
    left: 0;
    margin-top: 220px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 70px;

    .wrapper {
      .box {
        .box-label {
          display: none;
        }
      }
    }
  }

  .marketplace {
    margin-top: 0px;
  }

  .top-bar {
    margin-left: 0;
    width: 100%;
    margin-top: 30px !important;
    margin-bottom: 50px !important;
  }
}

@media only screen and (max-width: 768px) {
  .info-holder {
    display: none;
  }

  .stats {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    width: 97%;
    margin: 0 auto;
    float: none;
  }

  .top-bar {
    margin-top: 70px;
    margin-bottom: 0;
    height: 120px;
  }

  .connect-wallet,
  .disconnect-wallet {
    width: 170px;
    font-size: 12px !important;
  }
}


.info-holder {
  width: 350px;
  height: 50px;
  position: absolute;
  top: 45px;
  right: 200px;

  .busd {
    width: 25px;
    float: left;
    margin-right: 5px;
  }

  .address img {
    display: none;
  }

  .address,
  .balance {
    float: left;
    margin: 0;
    font-size: 14px !important;
    margin-right: 20px;
    font-weight: 600;
  }

  .address {
    margin-right: 20px;
  }

  .bsc {
    font-size: 14px;
    float: left;
    margin-top: 4px;
    margin-right: 10px;
    display: block;
    font-weight: 600;
  }
}
</style>
