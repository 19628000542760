import {
  Listing,
} from "@/types";
import { chainId, initializing, walletAddress } from "@/wallet/wallet";
import { ref } from "vue";

const gateway = import.meta.env.VITE_MARKETPLACE_URL;
const snooze = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

const defaultKickPrice = 0.002
const kickPrice = ref<number>(defaultKickPrice);

export async function getListings(
  filters: object = {},
  pageNumber: number = 0
): Promise<{
  count: number,
  listings: Array<Listing>
}> {
  await initializing.value;
  let retries = 0,
    listings;
  while (retries < 5) {
    try {
      listings = await (
        await fetch(
          `${gateway}/listing/page/${pageNumber}?chain-id=${chainId.value}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(filters),
          }
        )
      ).json();
      break;
    } catch (e) {
      console.log(e + " in getListings in api/index.js");
      await snooze(10000);
      retries++;
    }
  }
  return listings;
}

export async function getMyListings(): Promise<any> {
  try {
    await initializing.value;

    const result = await fetch(
      `${gateway}/listing/seller/${walletAddress.value}?chain-id=${chainId.value}`
    );
    return await result.json();
  } catch (e) {
    throw new Error(e as any);
  }
}

export async function getListingPageCount(filters: object = {}): Promise<any> {
  await initializing.value;
  let retries = 0,
    listings;
  while (retries < 5) {
    try {
      listings = await (
        await fetch(`${gateway}/listing/page-count?chain-id=${chainId.value}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(filters),
        })
      ).json();
      break;
    } catch (e) {
      console.log(e + " in getListings in api/index.js");
      await snooze(10000);
      retries++;
    }
  }
  return listings;
}

export async function getListing(listingId: number): Promise<any> {
  try {
    await initializing.value;
    const result = await fetch(
      `${gateway}/listing/${listingId}?chain-id=${chainId.value}`
    );
    return await result.json();
  } catch (e) {
    throw new Error(e as any);
  }
}

export async function getListingsBySeller(seller: string): Promise<Array<any>> {
  try {
    await initializing.value;

    if (!seller) {
      throw new Error("Please enter a valid seller address");
    }

    const result = await fetch(
      `${gateway}/listing/seller/${seller}?chain-id=${chainId.value}`
    );

    //DEV purposes
    // const result = await fetch(
    //   `https://apigateway.bithotel.io/listing/seller/${seller}?chain-id=56`
    // );

    return await result.json();
  } catch (e) {
    throw new Error(e as any);
  }
}

// TODO: set all NFTs in vuex store and change myNFTs getter to return based on chainId
// currently, we have to call this function each time we switch from chain
export async function getMyNFTs(): Promise<any> {
  await initializing.value;
  if (chainId.value == 56 || chainId.value == 97) {
    // BSC and BSC Testnet
    let chain = "bsc";

    if (import.meta.env.VITE_ENV != "prod") {
      chain += "%20testnet";
    }

    try {
      let nfts: Array<any> = [];
      let lastFetchCount = 0;
      let cursor = "";
      do {
        let requestUrl = `https://deep-index.moralis.io/api/v2/${walletAddress.value}/nft?chain=${chain}&format=decimal`;
        if (cursor) {
          requestUrl += `&cursor=${cursor}`;
        }
        let response = await fetch(requestUrl, {
          headers: { "x-api-key": import.meta.env.VITE_MORALIS_API_KEY },
        });
        let jsonResponse = await response.json();
        nfts = nfts.concat(jsonResponse.result);
        lastFetchCount = jsonResponse.result.length;
        cursor = jsonResponse.cursor;
      } while (lastFetchCount == 100);
      return nfts;
    } catch (e) {
      throw new Error(e as any);
    }
  }
}

export async function getExListingsByNft(
  nftAddress: string,
  tokenId: number | string,
  page?: number
): Promise<any> {
  let result: any;
  try {
    await initializing.value;

    if (!nftAddress || !tokenId) {
      throw new Error("Please enter a the required arguments");
    }

    if (page) {
      result = await fetch(
        `${gateway}/listing/executed/${nftAddress}/${tokenId}?chain-id=${chainId.value}&page=${page}`
      );
    } else {
      result = await fetch(
        `${gateway}/listing/executed/${nftAddress}/${tokenId}?chain-id=${chainId.value}`
      );
    }

    return await result.json();
  } catch (e) {
    throw new Error(e as any);
  }
}

export async function getKICKSprice(): Promise<number> {
  if (kickPrice.value !== defaultKickPrice || import.meta.env.MODE == "dev") {
    console.log("using cached price");
    return kickPrice.value;
  }
  let response = null;
  try {
    response = await fetch(
      "https://pro-api.coinmarketcap.com/v2/cryptocurrency/quotes?slug=getkicks&convert=USD", {
      headers: {
        'X-CMC_PRO_API_KEY': '454f3f28-be4f-441f-9e3a-978e0ca81ee8',
        "Content-Type": "application/json"
        },
    });
  } catch (ex) {
    return 0.002;
  } finally {
    if (response) {
      // success
      const json = await response.json();

      kickPrice.value = +json.data[22205].quote.USD.price;
      return kickPrice.value;
    }
    return 0.002;
  }
}
export async function getCollections(): Promise<any> {
  let collections;
  try {
    collections = await fetch(
      `${gateway}/collection/?chain-id=${chainId.value}`
    );
    return await collections.json();
  } catch (e) {
    console.log(e);
    await snooze(1000);
    return await getCollections();
  }
}

export async function getNftByAddress(
  address: string,
  tokenId: number = 1
): Promise<any> {
  try {
    const result = await fetch(`${gateway}/nft/address/${address}/${tokenId}`);
    return await result.json();
  } catch (e) {
    throw new Error(e as any);
  }
}

export async function getCollectionStats(
  address: string
): Promise<{
    one_day_volume: number,
    one_day_change: number,
    one_day_sales: number,
    one_day_average_price: number,
    seven_day_volume: number,
    seven_day_change: number,
    seven_day_sales: number,
    seven_day_average_price: number,
    thirty_day_volume: number,
    thirty_day_change: number,
    thirty_day_sales: number,
    thirty_day_average_price: number,
    total_volume: number,
    total_sales: number,
    total_supply: number,
    floor_price: number
  }> {
  try {
    const result = await fetch(`${gateway}/collection/${address}/stats`);
    return (await result.json()).stats;
  } catch (e) {
    throw new Error(e as any);
  }
}