import { createRouter, createWebHashHistory } from 'vue-router'
import Marketplace from '@/views/Marketplace.vue'

export default createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/',
      name: "Marketplace",
      component: Marketplace,
    },
    {
      path: "/listing/:listingId",
      name: "Listing",
      component: () => import("./views/Listing.vue"),
    },
    {
      path: "/nft/:address/:tokenId",
      name: "NFT",
      component: () => import("./views/Listing.vue"),
    },
    {
      path: "/my-nfts",
      name: "MyNFTs",
      component: () => import("./views/MyNFTs.vue"),
    },
  ],
})
