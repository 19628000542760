<template>
  <Siderbar class="sidebar" />
  <a href="/" class="logoRes"><img src="./assets/logo_zwart.png" /></a>
  <router-view />
  <notifications position="bottom right" width="500" />
  <modal />
</template>

<script lang="ts">
import { provide } from "vue";
import Siderbar from "@/components/Sidebar.vue";
import modal from "@/components/plugin/Modal.vue";
export default {
  components: {
    Siderbar,
  },
  created() {
    provide("pluginModal", {
      showModal: this.$showModal,
      showModalAsync: this.$showModalAsync,
      hidelModal: this.$hideModal,
    });
  },
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

#app {
  width: 100vw;
  font-family: 'Open Sans', sans-serif !important;
}

.logoRes {
  position: absolute;
  top: 30px;
  left: 40px;
  display: none;
  display: none;

  img {
    width: 120px;
  }
}

html {
  // background: linear-gradient(180deg, #2F2F42 0%, #717185 100%);
  background: #23232f;
  min-height: 100vh;
  // font-family: "Open Sans"; . s
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  max-width: 100%;
  scroll-behavior: smooth;
  font-family: 'Open Sans', sans-serif !important;
}

@media (max-width: 1024px) {
  .logoRes {
    display: block;
  }

  .sidebar {
    display: none;
  }
}
</style>
