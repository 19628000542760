<template v-if="item.rarity">
  <div class="container" :class="{ myNFT: myNFT }" @mouseover="hover = true" @mouseleave="hover = false"
    :style="`min-height:${myNFT ? '230px' : '230px'}`">
    <div @click="onClick">
      <div class="rarity" :class="rarity">
        {{ rarity }}
      </div>
      <div v-if="item.status == 'Added' && router.currentRoute.value.path == '/my-nfts'" class="for-sale">
        For sale
      </div>
      <div class="image-holder">
        <!-- <img src="../assets/greenKick.png" class="shoe" /> -->
        <!-- <img src="../assets/background_shoe.jpg" class="container-background" /> -->
        <img :src="image" class="shoe" />
      </div>
      <div class="kick-info">
        <h2>KICK #{{ token_id }}</h2>
        <div class="kick-type">All-Round</div>
        <div class="price" v-if="router.currentRoute.value.path != '/my-nfts'">
          {{ ceilPrice() }} <span class="currency">KICKS</span>
        </div>
      </div>
    </div>
    <!-- <ListingStatus v-if="!myNFT" :listing="item" top="50" bottom="0" :currency="currency" /> -->
  </div>
</template>
<script setup lang="ts">

import { onMounted, ref } from "vue";
import { initializing } from "@/wallet/wallet";
import { useRouter } from "vue-router";
import { getTraitValue } from "@/plugins";

const router = useRouter();
const { item, myNFT, bth } = defineProps(["item", "myNFT", "bth"]);

const hover = ref<boolean>(false);
const image = ref<string>("");
const rarity = ref<string>("");
const type = ref<string>("");
const token_id = ref<string>("");
const isListing = ref<boolean>(false);

onMounted(async () => {
  if (item.metadata === undefined) {
    rarity.value = item.rarity
    image.value = item.image;
    token_id.value = item.tokenId;
  } else {
    const metadata = typeof item.metadata == "string" ? JSON.parse(item.metadata) : item.metadata;

    rarity.value = getTraitValue(metadata.attributes, "Rarity");
    type.value = getTraitValue(metadata.attributes, "Type");
    image.value = metadata.image;
    token_id.value = item.token_id;
  }
  if (item.status == "Added") {
    isListing.value = true;
  }
  await initializing.value;
});

function onClick() {
  if (isListing.value) {
    router.push({
      name: "Listing",
      params: {
        listingId: item.listingId,
      }
    })
  } else {
    router.push({
      name: "NFT",
      params: {
        address: item.token_address,
        tokenId: item.token_id
      }
    })
  }
}

function ceilPrice() {
  return item.price.toFixed(2);
}
</script>
<style scoped lang="scss">
.Brick {
  background: #78D3E9 !important;
}

.B-Grade {
  background: #2ecc71 !important;
}

.OG {
  background: #EFE726 !important;
}

.Heat {
  background: #F5B24D !important;
}

.Grail {
  background: #FF4F4F !important;
}

.myNFT {
  height: 230px !important;
}

.container-background {
  width: 100%;
  position: absolute;
  top: -15px;
  left: 0;
  z-index: 0;
}

.shoe {
  position: relative;
  z-index: 2;
}

.container {
  margin-right: 2%;
  height: 235px;
  float: left;
  margin-bottom: 20px;
  // box-shadow: 0px 4px 45px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  background: #FFFFFF;
  // min-width: 320px;

  .button {
    position: absolute;
    bottom: 20px;
    left: calc(50% - 100px);
  }

  .season {
    position: absolute;
    width: 80px;
    height: 30px;
    left: 15px;
    top: 15px;
    text-align: center;
    line-height: 30px;
    color: var(--white);
    font-weight: 600;

    background: linear-gradient(109.12deg, #fd2929 -14.05%, #fe66c1 134.06%);
    border-radius: 5px;
  }

  $size: 180px;

  .circle {
    background: red;
    height: $size;
    width: $size;
    border-radius: calc($size / 2);
    margin: auto;
    margin-top: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-height: calc($size + 24px);
      width: 100%;
      object-fit: contain;
    }

    .small-image {
      width: 65%;
      max-height: 65%;
    }

    .character-gif {
      max-height: 300px;
    }
  }

  .circle-fade {
    height: calc($size - 30px);
    width: calc($size - 30px);
    border-radius: calc($size / 2);
    margin: auto;
    position: absolute;
    top: 100px;
    left: 120px;

    background: linear-gradient(132.71deg, #f1cb69 -12.6%, #f17969 112.6%);
    opacity: 0.9;
    filter: blur(100px);
  }

  .legendary {
    background: linear-gradient(90deg,
        #fcb819 33.58%,
        #f99124 85.82%) !important;
    // background: #fcb819 !important;
  }

  .rare {
    background: linear-gradient(90deg,
        #960de7 33.58%,
        #7418f6 85.82%) !important;
  }

  .common {
    background: linear-gradient(90deg,
        #00d1e6 33.58%,
        #00dacb 85.82%) !important;
  }

  .super_rare {
    background: linear-gradient(90deg,
        #e15288 33.58%,
        #d941a9 85.82%) !important;
  }

  .epic {
    background: linear-gradient(90deg,
        #f3793d 33.58%,
        #e4597b 85.82%) !important;
  }

  .uncommon {
    background: linear-gradient(90deg,
        #00e4aa 33.58%,
        #00e999 85.82%) !important;
  }

  .title {
    font-size: 24px;
  }

  button {
    color: white;
    background: green;
    width: 100px;
    margin: auto;
    border: 1px solid grey;
    border-radius: 3px;
    padding: 5px 10px;
  }

  .image-holder {
    width: 100%;
    height: 145px;
    background: #FAF9F6;
    position: relative;
    overflow: hidden;
    border-radius: 20px 20px 0px 0px;

    img {
      width: 220px;
      margin: 15px auto;
      display: block;
    }
  }

  .kick-info {
    width: 100%;
    height: auto;
    position: relative;

    h2 {
      color: #000000;
      font-weight: 800;
      font-size: 18px;
      text-align: left;
      margin-top: 25px;
      margin-left: 30px;
    }

    .kick-type {
      font-weight: 600;
      font-size: 14px;
      color: #969696;
      text-align: left;
      margin-left: 30px;
      margin-top: -10px;
    }
  }

  .price {
    color: #000;
    font-size: 16px;
    font-weight: 700;
    padding: 10px 20px;
    border-radius: 27px;
    position: absolute;
    right: 20px;
    top: 0;
    margin-top: 14px;
  }

  .currency {
    color: #969696;
    font-weight: 400;
  }

  .rarity {
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
    height: 25px;
    line-height: 25px;
    position: absolute;
    right: 10px;
    top: 10px;
    background: #EFE726;
    color: #fff;
    border-radius: 38px;
    font-size: 14px;
    z-index: 1;

    .rarity-holder {
      margin: 10px auto !important;
    }
  }

  .for-sale {
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
    height: 25px;
    line-height: 25px;
    position: absolute;
    left: 10px;
    top: 10px;
    background: black;
    color: #fff;
    border-radius: 38px;
    font-size: 14px;
    z-index: 1;
  }

  .epic {
    background: linear-gradient(132.71deg, #f1cb69 -12.6%, #f17969 112.6%);
  }

  .name {
    /* Parapgraph/Caps/Extra Bold */
    width: 100%;
    height: auto;
    margin-top: 60px;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 152.4%;
    /* or 24px */

    text-align: center;
    letter-spacing: 0.035em;
    text-transform: uppercase;

    /* White */

    color: var(--white);
  }

  .forsale {
    width: 100%;
    text-align: center;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 152.4%;
    text-transform: uppercase;
    margin-top: 20px;
    float: left;
    /* identical to box height, or 20px */

    text-align: center;
    letter-spacing: 0.035em;

    background: linear-gradient(180deg, #5ecdbf 0%, #62fea1 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;

    img {
      margin-left: 5px;
      margin-bottom: -5px;
    }
  }

  .usdEstimate {
    width: 100%;
    float: left;
    display: block;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 152.4%;
    text-align: center;
    letter-spacing: 0.035em;

    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -20px;
    /* Tint */

    color: #9393a4;
  }

  .information {
    width: 100%;
    height: auto;
    float: left;
    margin-top: 0px;

    .info-segment {
      width: 100%;
      height: auto;
      float: left;
      text-align: center;

      .tag {
        width: 100%;
        color: #717185;
        margin-top: 8px;
        display: block;
        font-size: 13px;
        margin-bottom: 5px;
      }

      .value-field {
        width: auto;
        height: auto;
        max-width: 140px;

        font-size: 14px;
        color: var(--white);
        padding: 8px 10px;
        background: linear-gradient(89.97deg, #2e2e3c -87.81%, #23232f 85.79%);
        border-radius: 5px;
        margin: 0px auto;
        position: relative;
      }
    }
  }

  .buy {
    width: 150px;
    height: 50px;
    line-height: 50px;
    background: #23232f;
    border: 1px solid #454558;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 0;

    font-family: "Open Sans";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 152.4%;
    margin: 8px auto 16px;
    // margin-top: 35px;
    /* or 24px */

    letter-spacing: 0.035em;
    text-transform: uppercase;

    /* White */

    color: var(--white);

    img {
      margin-bottom: -5px;
      margin-left: 5px;
      width: 20px;
    }
  }
}

@media (max-width: 1600px) {
  .kick-info .price {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .container {
    height: 290px;
  }

  .container .price {
    margin-top: 20px;
    left: 20px;
    right: inherit;
    top: 35px;
    font-size: 14px;
  }

  .container {
    margin-right: 0;
  }
}
</style>
