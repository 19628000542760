import { createPinia, defineStore } from "pinia";
import { reactive } from "vue";
import { getCollections, getListingsBySeller, getMyNFTs } from "./api";
import { walletAddress } from "./wallet/wallet";

export const useStore = defineStore(
  "nfts",
  () => {
    const myNFTs = reactive<Array<any>>([]);

    const getFilteredMyNFTs = (filters: any) => {
      let copy = myNFTs;
      if (filters.where.nft.collection.category.name) {
        copy = copy.filter(
          (nft) => {
            return nft.type ==
              filters.where.nft.collection.category.name ||
              nft.nft?.collection.category.name ==
              filters.where.nft.collection.category.name
          }
        );
      }

      if (filters.forSale == "Not For Sale") {
        copy = copy.filter((item) => item.status != "Added");
      } else if (filters.forSale == "For Sale") {
        copy = copy.filter((item) => item.status == "Added");
      }
      return copy;
    }

    const fetchMyNfts = async () => {
      const nfts = await getMyNFTs();
      const _myListings = await getListingsBySeller(walletAddress.value);
      const myListings = _myListings.map((listing: any) => {
        return {
          token_address: listing.nft.address,
          token_id: listing.nft.tokenId,
          metadata: JSON.stringify(listing.nft.metadata.data),
          ...listing,
        }
      })
      const filteredNfts = await filterNfts(nfts);
      setMyNFTs(filteredNfts.concat(myListings));
    }

    const setMyNFTs = (_nfts: Array<any>) => {
      Object.assign(myNFTs, _nfts);
    };

    return {
      myNFTs,
      fetchMyNfts,
      setMyNFTs,
      getFilteredMyNFTs,
    };
  },
  {
    persist: {
      enabled: true,
    },
  }
);

async function filterNfts(_nfts: Array<any>): Promise<Array<any>> {
  let nfts = [];
  const collections = await getCollections();
  for (let nft of _nfts) {
    let collection = collections.find(
      (c: any) => c.address.toLowerCase() == nft.token_address.toLowerCase()
    );
    if (collection) {
      nfts.push({
        ...nft,
      });
    }
  }
  return nfts;
};

export default createPinia();
