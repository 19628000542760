import { createApp } from 'vue'
import './style.css'
import App from './App.vue'
import router from './router'
import Wallet from "./wallet";
import store from './store';
import { supportedChains } from "./wallet/constants";
import { Modal } from './plugins';
import Notifications from "@kyvg/vue3-notification";
import mitt from 'mitt'

export const emitter = mitt();
const app = createApp(App);

app.config.globalProperties.emitter = emitter;
app.use(router);
app.use(store);
app.use(Modal);
app.use(Notifications);

app.use(Wallet, {
  theme: "dark",
  chains: supportedChains,
  infuraKey: "9aa3d95b3bc440fa88ea12eaa4456161",
  defaultChain: 56,
  networkType: "mainnet", // testnet | mainnet
  chainIds: [import.meta.env.VITE_CHAINID],
});

app.mount('#app');